import React from "react";

import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import Section from "../components/section";

const CookiesPolicy = (props) => {
  return <Layout staticHeader>
    <SEO title="Política de Cookies" description="" location={props.location}/>

    <Section title="Política de Cookies">
      <div className="container" style={{marginBottom: "45px"}}>
        <p>ADVENTUREES ALLIANCE, S.L., (en adelante, el &ldquo;<strong><em>TITULAR</em></strong>&rdquo;) informa, a trav&eacute;s de la presente Pol&iacute;tica de Cookies, acerca de la utilizaci&oacute;n de dispositivos de almacenamiento y recuperaci&oacute;n de datos en equipos terminales de los Usuarios.</p>
        <p>&nbsp;</p>
        <h3>1.- &iquest;Qu&eacute; son las cookies?</h3>
        <p>Las cookies son archivos o ficheros que se descargan en el ordenador/smartphone/tablet del Usuario al acceder a determinadas p&aacute;ginas web y aplicaciones que permiten almacenar preferencias de la navegaci&oacute;n del Usuario, haciendo que la interacci&oacute;n entre el Usuario y el Sitio Web sea m&aacute;s r&aacute;pida y f&aacute;cil.</p>
        <p>La informaci&oacute;n recogida por las cookies es an&oacute;nima y no contiene ninguna informaci&oacute;n sensible ya que no recogen datos que puedan identificar personalmente al Usuario. En cualquier caso, el Usuario podr&aacute; acceder a la configuraci&oacute;n de su navegador para modificar y/o bloquear la instalaci&oacute;n de las Cookies enviadas desde el Sitio Web, sin que ello impida al acceso a los contenidos.</p>
        <p>&nbsp;</p>
        <h3>2.- &iquest;Para qu&eacute; utiliza el TITULAR las cookies?</h3>
        <p>De conformidad con en el Considerando 30 del Reglamento General de Protecci&oacute;n de Datos (&ldquo;<strong><em>RGPD</em></strong>&rdquo;), el presente Sitio Web utiliza cookies u otros dispositivos de almacenamiento y recuperaci&oacute;n de informaci&oacute;n para realizar un seguimiento de las interacciones de los Usuarios con los servicios ofrecidos en el Sitio Web.</p>
        <p>Las cookies permiten reconocer el navegador de un Usuario, as&iacute; como el tipo de dispositivo desde el que se accede a el Sitio Web, y se utilizan para facilitar la pr&oacute;xima visita del Usuario y hacer que el Sitio Web resulte m&aacute;s &uacute;til.</p>
        <p>El TITULAR utiliza las cookies para:</p>
        <ul>
          <li>Optimizar la navegaci&oacute;n del Usuario mediante el seguimiento de la sesi&oacute;n.</li>
        </ul>
        <ul>
          <li>Recopilar informaci&oacute;n para optimizar la navegaci&oacute;n y mejorar el Sitio Web.</li>
        </ul>
        <p>El Sitio Web utiliza los siguientes tipos de cookies:</p>
        <ul>
          <li><strong>Funcionales</strong>: Las cookies funcionales dan al usuario informaci&oacute;n que enriquece la experiencia de navegaci&oacute;n como, por ejemplo, mostrar el pron&oacute;stico del tiempo basado en su ubicaci&oacute;n.</li>
        </ul>
        <table width="652" cellspacing="0" cellpadding="7">
          <colgroup>
            <col width="162" />
            <col width="286" />
            <col width="75" />
            <col width="71" />
          </colgroup>
          <thead>
            <tr valign="top">
              <th width="162">
                <p align="center">
                  <strong>Nombre</strong>
                </p>
              </th>
              <th width="286">
                <p align="center">
                  <strong>Finalidad</strong>
                </p>
              </th>
              <th width="75">
                <p align="center">
                  <strong>Caducidad</strong>
                </p>
              </th>
              <th width="71">
                <p align="center">
                  <strong>Proveedor</strong>
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td width="106" height="17">
                <p align="center">
                  <strong>PHPSESSID</strong>
                </p>
              </td>
              <td width="193" valign="top">
                <p align="left">
                  Almacenar información de sesión del usuario (si ha
                  iniciado sesión, idioma preferido, redirecciones
                  dentro de la plataforma, etc.)
                    </p>
              </td>
              <td width="75" valign="top">
                <p align="center">
                  24 horas o al finalizar la sesión
                    </p>
              </td>
              <td width="80" valign="top">
                <p align="center">
                  El IMPULSOR
                    </p>
              </td>
            </tr>
            <tr>
              <td width="162" height="18">
                <p align="center">
                  <strong>_evCo</strong>
                </p>
              </td>
              <td width="286" valign="top">
                <p align="left">
                  Se usa para recordar si el chat integrado se maximizó de
                  forma automática.
                    </p>
              </td>
              <td width="75" valign="top">
                <p align="center">
                  Al finalizar la sesión
                    </p>
              </td>
              <td width="71" valign="top">
                <p align="center">
                  Livebeep
                    </p>
              </td>
            </tr>
            <tr>
              <td width="162" height="26">
                <p align="center">
                  <strong>_evCoLT</strong>
                </p>
              </td>
              <td width="286" valign="top">
                <p align="center">
                  En caso de haberse realizado una consulta, se usa recordar
                  el usuario, la sesión de chat y el operador que ha atendido
                  dicha consulta.
                    </p>
              </td>
              <td width="75" valign="top">
                <p align="center">
                  15 días
                    </p>
              </td>
              <td width="71" valign="top">
                <p align="center">
                  Livebeep
                    </p>
              </td>
            </tr>
            <tr>
              <td width="162" height="26">
                <p align="center">
                  <strong>
                    _evAd, _evCoupon, _evSubscription, _evPromt
                        </strong>
                </p>
              </td>
              <td width="286" valign="top">
                <p align="center">
                  Se usan para recordar si el usuario ha visualizado un
                  anuncio, un cupón, una suscripción o un aviso
                  respectivamente. Estas cookies se instalan únicamente si se
                  ha visualizado alguno de estos eventos.
                    </p>
              </td>
              <td width="75" valign="top">
                <p align="center">
                  Al finalizar la sesión
                    </p>
              </td>
              <td width="71" valign="top">
                <p align="center">
                  Livebeep
                    </p>
              </td>
            </tr>
          </tbody>
        </table>
        <ul>
          <li><strong>Estad&iacute;sticas/anal&iacute;ticas</strong>: Estas cookies recogen informaci&oacute;n relativa al uso de la p&aacute;gina como la permanencia en p&aacute;gina y los contenidos visitados por los lectores para medir su rendimiento y mejorar la navegaci&oacute;n.</li>
        </ul>
        <p>Los objetivos principales que se persiguen con este tipo de cookies son:</p>
        <ul>
          <li>Permitir la identificaci&oacute;n an&oacute;nima de los Usuarios navegantes y por lo tanto la contabilizaci&oacute;n aproximada del n&uacute;mero de visitantes.</li>
          <li>Identificar de forma an&oacute;nima los contenidos m&aacute;s visitados.</li>
        </ul>
        <ul type="disc">
          <li>Saber si el Usuario que est&aacute; accediendo es nuevo o repite visita.</li>
        </ul>
        <p>A continuaci&oacute;n, se detallan las cookies anal&iacute;ticas que se utilizan en el Sitio Web: (ejemplo)</p>
        <table width="512" cellspacing="0" cellpadding="7">
          <colgroup>
            <col width="107" />
            <col width="201" />
            <col width="75" />
            <col width="71" />
          </colgroup>
          <thead>
            <tr valign="top">
              <th width="107">
                <p align="center">
                  <strong>Nombre</strong>
                </p>
              </th>
              <th width="201">
                <p align="center">
                  <strong>Finalidad</strong>
                </p>
              </th>
              <th width="75">
                <p align="center">
                  <strong>Caducidad</strong>
                </p>
              </th>
              <th width="71">
                <p align="center">
                  <strong>Proveedor</strong>
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td width="107" height="18">
                <p align="center">
                  <strong>_ga</strong>
                </p>
              </td>
              <td width="201" valign="top">
                <p align="left">
                  Se usa para distinguir a los usuarios.
                </p>
              </td>
              <td width="75" valign="top">
                <p align="center">
                  2 años
                </p>
              </td>
              <td width="71" valign="top">
                <p align="center">
                  Google Analytics
                </p>
              </td>
            </tr>
            <tr>
              <td width="107" height="26">
                <p align="center">
                  <strong>_gat</strong>
                </p>
              </td>
              <td width="201" valign="top">
                <p align="center">
                  Se usa para limitar el porcentaje de solicitudes.
                </p>
              </td>
              <td width="75" valign="top">
                <p align="center">
                  1 minuto
                </p>
              </td>
              <td width="71" valign="top">
                <p align="center">
                  Google Analytics
                </p>
              </td>
            </tr>
            <tr>
              <td width="107" height="26">
                <p align="center">
                  <strong>_gid</strong>
                </p>
              </td>
              <td width="201" valign="top">
                <p align="center">
                  Se usa para distinguir a los usuarios.
                </p>
              </td>
              <td width="75" valign="top">
                <p align="center">
                  24 horas
                </p>
              </td>
              <td width="71" valign="top">
                <p align="center">
                  Google Analytics
                </p>
              </td>
            </tr>
            <tr>
              <td width="107" height="25">
                <p align="center">
                  <strong>_gac_&lt;id-propiedad&gt;</strong>
                </p>
              </td>
              <td width="201" valign="top">
                <p align="center">
                  Incluye información de la campaña relativa al
                  usuario.
                </p>
              </td>
              <td width="75" valign="top">
                <p align="center">
                  90 días
                </p>
              </td>
              <td width="71" valign="top">
                <p align="center">
                  Google Analytics
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>&nbsp;</p>
        <h3>3.- &iquest;C&oacute;mo deshabilitar las cookies?</h3>
        <p>Aquellas cookies que no sean obligatorias para la navegaci&oacute;n del presente Sitio Web podr&aacute;n deshabilitarse en la configuraci&oacute;n del Sitio Web.</p>
        <p>Estos ajustes se encuentran en el pie de p&aacute;gina del Sitio Web. Adem&aacute;s, todos los navegadores permiten hacer cambios para desactivar la configuraci&oacute;n de las cookies.</p>
        <p>Este es el motivo por el que la mayor&iacute;a de navegadores ofrecen la posibilidad de administrar las cookies, para obtener un control m&aacute;s preciso sobre la privacidad.</p>
        <p>Estos ajustes se encuentran ubicados en las "opciones" o "preferencias" del men&uacute; de su navegador.</p>
        <p>A continuaci&oacute;n, podr&aacute; encontrar los links de cada navegador para deshabilitar las cookies siguiendo las instrucciones:</p>
        <p>&nbsp;</p>
        <p>- Internet Explorer <a target="_blank" rel="noreferrer noopener" href="https://goo.gl/iU2wh2">https://goo.gl/iU2wh2</a></p>
        <ul>
          <li>En el men&uacute; de herramientas, selecciones &ldquo;Opciones de Internet&rdquo;.</li>
          <li>Haga clic en la pesta&ntilde;a de privacidad.</li>
          <li>Podr&aacute; configurar la privacidad con un cursor con seis posiciones que le permite controlar la cantidad de cookies que se instalar&aacute;n: Bloquear todas las cookies, Alta, Media Alto, Media (nivel por defecto), Baja y Aceptar todas las cookies.</li>
        </ul>
        <p>&nbsp;</p>
        <p>- Mozilla Firefox <a target="_blank" rel="noreferrer noopener" href="http://goo.gl/QXWYmv">http://goo.gl/QXWYmv</a></p>
        <ul>
          <li>En la parte superior de la venta de Firefox hacer clic en el men&uacute; Herramientas.</li>
          <li>Seleccionar Opciones.</li>
          <li>Seleccionar el panel Privacidad.</li>
          <li>En la opci&oacute;n Firefox podr&aacute; elegir Usar una configuraci&oacute;n personalizada de su historial, as&iacute; como otras cuestiones relacionadas con su Privacidad.</li>
        </ul>
        <p>&nbsp;</p>
        <p>- Google Chrome <a target="_blank" rel="noreferrer noopener" href="http://goo.gl/fQnkSB">http://goo.gl/fQnkSB</a></p>
        <ul>
          <li>Hacer clic en el men&uacute; situado en la barra de herramientas.</li>
          <li>Seleccionar Configuraci&oacute;n.</li>
          <li>Hacer clic en Mostar opciones avanzadas.</li>
          <li>En la selecci&oacute;n &ldquo;Privacidad&rdquo; hacer clic en el bot&oacute;n Configuraci&oacute;n de contenido.</li>
          <li>En la selecci&oacute;n de Cookies, se pueden configurar las opciones.</li>
        </ul>
        <p>&nbsp;</p>
        <p>- Safari <a target="_blank" rel="noreferrer noopener" href="https://goo.gl/PcjEm3">https://goo.gl/PcjEm3</a>; <a target="_blank" rel="noreferrer noopener" href="https://goo.gl/dQywEo">https://goo.gl/dQywEo</a></p>
        <ul>
          <li>En el men&uacute; de configuraci&oacute;n seleccione la opci&oacute;n de &ldquo;Preferencias&rdquo;.</li>
          <li>Abra la pesta&ntilde;a de privacidad.</li>
          <li>Seleccione la opci&oacute;n que quiera de la secci&oacute;n de &ldquo;bloquear cookies&rdquo;.</li>
          <li>Recuerde que ciertas funciones y la plena funcionalidad del Sitio Web pueden no estar disponibles despu&eacute;s de deshabilitar las cookies.</li>
        </ul>
        <p>Si no desea ser rastreado por las cookies, Google ha desarrollado un complemento para instalar en su navegador al que puede acceder en el siguiente enlace:&nbsp; <a target="_blank" rel="noreferrer noopener" href="http://goo.gl/up4ND">http://goo.gl/up4ND</a>.</p>
        <p>&nbsp;</p>
        <h3>4.- Cookies en los dispositivos m&oacute;viles</h3>
        <p>El titular del Sitio Web tambi&eacute;n usa cookies u otros dispositivos de almacenamiento en dispositivos m&oacute;viles.</p>
        <p>Aquellas cookies que no sean obligatorias para la navegaci&oacute;n del presente Sitio Web podr&aacute;n deshabilitarse en la configuraci&oacute;n del Sitio Web.</p>
        <p>Estos ajustes se encuentran en el pie de p&aacute;gina del Sitio Web. Adem&aacute;s, al igual que sucede en los navegadores de ordenadores, lo navegadores de los dispositivos m&oacute;viles permiten realizar cambios en las opciones o ajustes de privacidad para desactivar o eliminar las cookies.</p>
        <p>Si desea modificar las opciones de privacidad siga las instrucciones especificadas por el desarrollador de su navegador para dispositivo m&oacute;vil.</p>
        <p>A continuaci&oacute;n, podr&aacute; encontrar algunos ejemplos de los links que le guiar&aacute;n para modificar las opciones de privacidad en su dispositivo m&oacute;vil:</p>
        <p>- IOS: <a target="_blank" rel="noreferrer noopener" href="http://goo.gl/61xevS">http://goo.gl/61xevS</a></p>
        <p>- Windows Phone: <a target="_blank" rel="noreferrer noopener" href="https://goo.gl/tKyb0y">https://goo.gl/tKyb0y</a></p>
        <p>- Chrome Mobile: <a target="_blank" rel="noreferrer noopener" href="http://goo.gl/XJp7N">http://goo.gl/XJp7N</a></p>
        <p>- Opera Mobile: <a target="_blank" rel="noreferrer noopener" href="http://goo.gl/Nzr8s7">http://goo.gl/Nzr8s7</a></p>
        <p>&nbsp;</p>
        <h3>5.- Aceptaci&oacute;n de cookies</h3>
        <p>El presente Sitio Web no instala nuestras cookies en los dispositivos de los usuarios hasta que estos hayan aceptado la instalaci&oacute;n de estas.</p>
        <p>Le informamos que, en el caso de no aceptar la instalaci&oacute;n de cookies o de deshabilitar alguna de estas en la configuraci&oacute;n, es posible que ciertos servicios no est&eacute;n disponibles sin la utilizaci&oacute;n de estas o que no pueda acceder a determinados servicios ni tampoco aprovechar por completo todo lo que este Sitio Web le ofrece.</p>
      </div>
    </Section>
  </Layout>
}

export default CookiesPolicy;
